@use '../../../sass/variables';

.change-country {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 45px;

    .panel-content-left {
        font-family: variables.$romanFontStack;
        font-weight: bold;
    }

    .panel-body {
        padding-left: 15px;
        padding-right: 15px;

        .panel-content {
            &:first-child {
                margin-top: 15px;
            }

            &:last-child {
                margin-bottom: 15px;
            }
        }
    }

    .btn-expand-collapse {
        &:hover,
        &:focus {
            outline: 1px solid variables.$solBlue;
        }
    }

    .panel-group {
        margin-bottom: 0;

        .panel {
            border: 0;
            border-bottom: 4px solid variables.$white;
            border-radius: 0;
            box-shadow: none;
            margin-top: 0;
            padding-bottom: 0;
            padding-top: 0;

            &:last-of-type {
                border-bottom: 0;
            }
        }

        //sass-lint:disable-block class-name-format
        .Collapsible__trigger {
            background-color: variables.$proGreyLight;
            border: 0;
            color: variables.$proTextGray;
            cursor: pointer;
            display: block;
            font-size: variables.$bodyIncreasedSize;
            padding-bottom: 15px;
            padding-left: 19px;
            padding-right: 43px;
            padding-top: 15px;
            position: relative;
            text-decoration: none;
            transition: background-color 0.4s ease;

            //sass-lint:enable class-name-format

            &::after {
                color: variables.$proDarkBlue;
                content: '\77';
                display: block;
                font-family: variables.$iconFontStack;
                font-size: variables.$h1Size;
                height: 14px;
                line-height: 8px;
                margin-top: -7px;
                opacity: 0.5;
                position: absolute;
                right: 15px;
                text-align: center;
                text-decoration: none;
                text-indent: -5px;
                top: 50%;
                transform: rotate(180deg);
                transition: transform 0.4s ease, opacity 0.4s ease;
                width: 23px;
            }

            &:hover,
            &:focus {
                background-color: variables.$proLilacHover;
                outline: 1px solid variables.$solBlue;

                &::after {
                    opacity: 1;
                }
            }

            &.is-open {
                &::after {
                    transform: rotate(0deg);
                }
            }
        }
    }

    .country-wrapper {
        margin-bottom: 15px;
        padding: 10px 0;

        .country-name {
            word-break: break-word;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}

@media only screen and (max-width: 767px) {
    .change-country {
        .country-wrapper {
            padding: 0;
        }
    }
}
